<template >
  <div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'peoManagement',
  components: {
  }
}
</script>
<style lang="less" scoped>
</style>
